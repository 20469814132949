<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h3 class="mb-15">Quikstone Capital Solutions Portal Log In</h3>
				<a-alert v-if="loginFailed" message="We could not find a user with those credentials. Please try again." type="warning" show-icon />
				<a-alert v-if="serverFailure" message="A server error prevented us from authenticating you. Please try again later." type="error" show-icon />
				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleLogin"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Email" :colon="false">
						<a-input
						v-decorator="[
						'email',
						{ initialValue: emailReminder, rules: [{ required: true, message: 'Please input your email!' }] },
						]" placeholder="" />
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]" type="password" placeholder="" />
					</a-form-item>
					<a-form-item class="mb-10">
    					<a-switch v-model="rememberMe" /> Remember Email
					</a-form-item>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							SIGN IN
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

				<p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-up" class="font-bold text-dark">Sign Up</router-link></p>

				<p class="font-semibold text-muted">Forgot your password? <router-link to="/forgot-password" class="font-bold text-dark">Reset It</router-link></p>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/bg-login-money.jpg" alt="">
				<!--<h5>Serving businesses nationwide since 2005</h5>-->
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
import User from '../models/user';

export default ({
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},
	created() {
		if (this.loggedIn) {
			this.$router.push("/dashboard");
		} else {
			//document.querySelector("input[id=normal_login_email]").value(localStorage.getItem("email"))
		}
	},
	data() {
		return {
			rememberMe: true,
			loginFailed: false,
			serverFailure: false,
			user: new User('', ''),
			emailReminder: localStorage.getItem('email'),
		}
	},
	beforeCreate() {
		// Creates the form and adds to it component's "form" property.
		this.form = this.$form.createForm(this, { name: 'normal_login' });
	},
	methods: {
		handleLogin(e) {
			e.preventDefault();
			
			this.loginFailed = false;
			this.serverFailure = false;
			
			this.form.validateFields((err, values) => {
				this.user.email = values.email;
				this.user.password = values.password;

				if(this.rememberMe){
					localStorage.setItem("email", this.user.email);
				} else {
					localStorage.removeItem("email");
				}

				if ( !err ) {
					this.$store.dispatch("auth/login", this.user).then(
						() => {
							this.$router.push("/dashboard");
						},
						(error) => {
							console.log(error);
							if(error.response.status == 404){
								this.loginFailed = true;
							}
							if(error.response.status == 500){
								this.serverFailure = true;
							}
							if(error.response.status == 409)
								this.$router.push("/userawaitingverification");
							
							this.loading = false;
							this.message =
								(error.response &&
								error.response.data &&
								error.response.data.message) ||
								error.message ||
								error.toString();
						}
					);

				}
			});
		},
	},
})
</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>